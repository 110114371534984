.App {
	text-align: center;
	background-size: contain !important;
	min-height: 100vh;
}
body {
	top: 0 !important;
}
.logos {
	width: 35% !important;
	position: absolute !important;
	top: 0% !important;
	right: 32% !important;
	z-index: 9 !important;
	background-color: #ffffff !important;
	border-radius: 0 0 25px 25px !important;
	padding: 5px 15px 10px 15px !important;
	box-shadow: 0px 0px 10px 0px var(--color1st) !important;
}
.hr {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr.svg) 50% 0 no-repeat !important;
}
.hr2 {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr2.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr2.svg) 50% 0 no-repeat !important;
}
.hr3 {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr3.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr3.svg) 50% 0 no-repeat !important;
}
.hr4 {
	height: 30px !important;
	opacity: 1 !important;
	background-color: var(--color1st) !important;
	-webkit-mask: url(./files/hr4.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr4.svg) 50% 0 no-repeat !important;
}
.profilePhoto {
	box-shadow: 0px 2px 7px 0px #000000 !important;
	border: 1px solid var(--color1st) !important;
}
.bannerPhoto {
	clip-path: polygon(50% 100%, 100% 99%, 100% 0, 0 0, 0 99%) !important;
}

@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
.title span,
.title {
	font-family: "Merienda", cursive !important;
}
#borderChoose {
	border: 1px solid white !important;
}
#borderChoose .hr,
#borderChoose .hr2,
#borderChoose .hr3,
#borderChoose .hr4 {
	background-color: white !important;
	width: 100% !important;
}
#borderChoose:hover .hr,
#borderChoose:hover .hr2,
#borderChoose:hover .hr3,
#borderChoose:hover .hr4 {
	background-color: var(--color1st) !important;
	width: 100% !important;
}

.MuiInputBase-formControl::before,
.MuiInputBase-formControl::after,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border-color: var(--color1st) !important;
}

.MuiInputBase-input,
.MuiOutlinedInput-root,
.MuiInputLabel-root {
	background-color: transparent !important;
	color: var(--color1st) !important;
}
.icons .MuiButtonBase-root {
	background-color: transparent !important;
	border: 1px solid var(--color2nd) !important;
	color: var(--color2nd) !important;
}
.buttonTop .MuiButton-root {
	background-color: var(--color2nd) !important;
	color: white !important;
	border: 1px solid white !important;
}

.icons .MuiButtonBase-root:hover {
	background-color: var(--color2nd) !important;
	color: white !important;
}
.icons .MuiButtonBase-root:hover .fi {
	background-color: var(--color2nd) !important;
	color: white !important;
}
.contactList .MuiButtonBase-root,
.contactList .MuiButton-root {
	background-color: var(--color2nd) !important;
	border: 1px solid var(--color2nd) !important;
	color: white !important;
}
.contactList .MuiButtonBase-root:hover,
.contactList .MuiButton-root:hover {
	background-color: transparent !important;
	color: var(--color2nd) !important;
}

.sendButton {
	background-color: var(--color2nd) !important;
	color: white !important;
	border: 1px solid var(--color2nd) !important;
}

.sendButton:hover {
	background-color: transparent !important;
	color: var(--color2nd) !important;
}

.MuiButtonBase-root {
	background-color: var(--color2nd) !important;
	color: transparent !important;
	color: white !important;
}
.MuiButtonBase-root:hover {
	background-color: white !important;
	color: var(--color2nd) !important;
	border: 1px solid var(--color2nd) !important;
}
.activeButton {
	background-color: white !important;
	color: var(--color2nd) !important;
	border: 1px solid var(--color2nd) !important;
	width: 90% !important;
	border-radius: 15px !important;
}
.dashboard .MuiSvgIcon-root {
	width: 0.8em;
	height: 0.8em;
	min-width: 41px;
	color: white;
}
.dashboard .MuiListItemIcon-root {
	color: white;
	min-width: 22px !important;
}
.activeButton .MuiSvgIcon-root {
	color: var(--color1st) !important;
}
.MuiButtonBase-root:hover .MuiListItemIcon-root {
	color: var(--color2nd) !important;
}
.MuiTableCell-head {
	color: var(--color1st) !important;
}
.MuiTableBody-root td {
	color: var(--color1st) !important;
	padding: 0 7px !important;
}
.dashboard .MuiDrawer-paper {
	background-color: var(--color1st) !important;
}
.dashboard .MuiDrawer-paper {
	background-color: var(--color1st) !important;
}
.dashboard .MuiTypography-root {
	font-size: 0.8rem;
}
.dashboard .MuiListItem-root {
	color: white;
	padding: 4px 1px;
	width: 90% !important;
	border-radius: 15px !important;
	margin: auto !important;
	background-color: transparent !important;
}

.dashboard .MuiListItem-root:hover .MuiSvgIcon-root,
.dashboard .MuiAccordionSummary-root:hover .MuiSvgIcon-root {
	color: white;
}
.dashboard .activeButton .MuiSvgIcon-root {
	color: var(--color2nd) !important;
}

.dashboard .link {
	text-decoration: none;
	font-weight: bold;
}

.dashboard .MuiAvatar-root {
	width: 65px;
	height: 65px;
}
.dashboard .MuiAccordionSummary-content {
	margin: 0 !important;
}
.dashboard .MuiAccordionSummary-root {
	flex-direction: row !important;
	color: white;
	padding: 0;
	min-height: auto !important;
	padding: 7px 1px;
}
.dashboard .MuiAccordionSummary-root:hover {
	background-color: var(--color1st);
}
.dashboard .MuiAccordionDetails-root {
	padding: 0;
}
.dashboard .MuiList-root {
	padding: 0 !important;
	background-color: var(--color1st);
}
.swiper-pagination-bullet-active {
	background-color: var(--color1st) !important;
}
.logout {
	border: 1px solid var(--color2nd) !important;
	background-color: var(--color1st) !important;
	color: var(--color2nd) !important;
}
.textColor,
.MuiFormHelperText-root {
	color: var(--color1st) !important;
}
input.Mui-disabled,
.MuiOutlinedInput-root .Mui-disabled {
	-webkit-text-fill-color: var(--color1st) !important;
	color: var(--color1st) !important;
}
.borderColor {
	border-color: var(--color1st) !important;
}
.bgColor {
	background-color: var(--color1st) !important;
}

/* .loader span span {
	background-color: var(--color1st) !important;
} */

.MuiRating-root {
	color: var(--color1st) !important;
}

.share {
	opacity: 40% !important;
	cursor: pointer;
	z-index: 999;
}

.share:hover {
	opacity: 100% !important;
	background-color: transparent !important;
}

.share .icon {
	animation: zoom 2s linear infinite !important;
}

@keyframes zoom {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.3);
	}

	100% {
		transform: scale(1);
	}
}
.swal2-container {
	z-index: 9999 !important;
}
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-thumb {
	background: var(--color1st) !important;
}
.home {
	height: 100vh !important;
}
.marvel-device.note8 {
	margin: 0 auto !important;
	transform: scale(0.67) !important;
	-webkit-transform: scale(0.67) !important;
	transform-origin: 0 0 !important;
	-webkit-transform-origin: 0 0 !important;
	display: block !important;
	width: 415px !important;
	height: 830px !important;
}
.note8 .screen {
	background: white !important;
}
.marvel-device.note8 .shadow {
	box-shadow: inset 0 0 0 0 white, inset 0 0 30px 0 rgb(255 255 255 / 50%),
		0 0 0px 0 white, 0 0 0 0 rgb(255 255 255 / 50%) !important;
}

.schedule .MuiButtonBase-root {
	background-color: transparent !important;
	border: 1px solid var(--color2nd) !important;
	color: var(--color2nd) !important;
	margin: 3px;
}
.schedule .Mui-selected {
	color: white !important;
	border: 1px solid var(--color1st) !important;
	background-color: var(--color1st) !important;
}
.schedule .MuiCalendarPicker-root {
	margin: 0 !important;
}

.schedule .selectedTime {
	color: white !important;
	border: 1px solid var(--color1st) !important;
	background-color: var(--color1st) !important;
}
.schedule .confirm {
	color: white !important;
	border: 1px solid var(--color1st) !important;
	background-color: var(--color1st) !important;
}
.MuiInputLabel-root {
	white-space: unset !important;
	font-size: small;
	font-weight: bold;
}
.invite .productCard {
	background-color: transparent !important;
	border: 1px solid var(--color1st) !important;
	color: var(--color1st) !important;
	border-radius: 11px;
}
.invite .button {
	background-color: var(--color1st) !important;
	color: white !important;
}
.invite .activeCard {
	background-color: transparent !important;
	border: 4px solid var(--color1st) !important;
	color: var(--color1st) !important;
	border-radius: 11px;
}
.MuiCalendarPicker {
	overflow: visible !important;
}
.wrapper {
	padding: 0.5rem;
	border: 1px solid var(--color1st) !important;
}
.editor {
	min-height: 15rem;
	border: 1px solid var(--color1st) !important;
	padding: 0 5px;
}
.toolbar {
	border: 1px solid var(--color1st) !important;
}
.username ::placeholder {
	color: var(--color1st) !important;
}

.username input[type="text"]:focus {
	border: 2px solid var(--color1st) !important;
	outline: none !important;
}
.pagination .MuiButtonBase-root {
	background-color: transparent !important;
	color: var(--color1st) !important;
	min-width: 30px !important;
	height: 30px !important;
}
.pagination .Mui-selected {
	background-color: var(--color1st) !important;
	color: white !important;
}
.textLeft {
	text-align: left;
}
.textRight {
	text-align: right;
}

.online .MuiBadge-badge {
	transform: scale(1) translate(0%, -0%) !important;
	border: 1px solid white;
}
.onlineProfile .MuiBadge-badge {
	transform: scale(1) translate(35%, -20%) !important;
	border: 1px solid white;
}

.react-player {
	height: auto !important;
	aspect-ratio: 16/9 !important;
	overflow: hidden !important;
	background-color: black !important;
}

.dayCellEvent {
	height: 35px !important;
}
.eventTitle {
	font-size: 1.1rem !important;
	padding: 0 14px !important;
	line-height: normal !important;
}
.dailyEventTitle {
	font-size: 1rem !important;
	line-height: normal !important;
}
.manageShare .Mui-selected {
	background-color: white !important;
	color: var(--color1st) !important;
	border: 3px solid var(--color1st) !important;
}
.MuiPopover-paper {
	width: 300px !important;
}
.chat .MuiChip-label {
	white-space: break-spaces !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}
.calander .Mui-disabled {
	background-color: #80808061 !important;
	color: white !important;
}
.timeDate .Mui-selected {
	color: var(--color1st) !important;
	background-color: white !important;
	border: 3px solid var(--color1st) !important;
}
.dailyEventWrapper {
	padding: 0 !important;
}
.rents .MuiTabScrollButton-root.Mui-disabled {
	opacity: 1 !important;
	background-color: gray !important;
}
.skiptranslate iframe {
	visibility: hidden !important;
}

/* Text on border line */
.separator {
	display: flex;
	align-items: center;
	text-align: center;
	font-weight: bold;
	margin: 0.5rem 0;
}

.separator::before,
.separator::after {
	content: "";
	flex: 1;
	border-bottom: 5px solid;
}

.separator:not(:empty)::before {
	margin-right: 0.35em;
}

.separator:not(:empty)::after {
	margin-left: 0.35em;
}

.loader {
	width: 32px;
	height: 90px;
	display: block;
	margin: 20px auto;
	position: relative;
	border-radius: 50% 50% 0 0;
	border-bottom: 10px solid #192a56;
	background-color: #fff;
	background-image: radial-gradient(
			ellipse at center,
			#fff 34%,
			#192a56 35%,
			#192a56 54%,
			#fff 55%
		),
		linear-gradient(#192a56 10px, transparent 0);
	background-size: 28px 28px;
	background-position: center 20px, center 2px;
	background-repeat: no-repeat;
	box-sizing: border-box;
	animation: animloaderBack 1s linear infinite alternate;
	border: 1px solid #192a56;
}
.loader::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 64px;
	height: 44px;
	border-radius: 50%;
	box-shadow: 0px 15px #192a56 inset;
	top: 67px;
}
.loader::after {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
	width: 34px;
	height: 34px;
	top: 112%;
	background: radial-gradient(
		ellipse at center,
		#ffdf00 8%,
		rgba(249, 62, 0, 0.6) 24%,
		rgba(0, 0, 0, 0) 100%
	);
	border-radius: 50% 50% 0;
	background-repeat: no-repeat;
	background-position: -44px -44px;
	background-size: 100px 100px;
	box-shadow: 4px 4px 12px 0px rgba(255, 61, 0, 0.5);
	box-sizing: border-box;
	animation: animloader 1s linear infinite alternate;
}

@keyframes animloaderBack {
	0%,
	30%,
	70% {
		transform: translateY(0px);
	}
	20%,
	40%,
	100% {
		transform: translateY(-5px);
	}
}

@keyframes animloader {
	0% {
		box-shadow: 4px 4px 12px 2px #192a56;
		width: 34px;
		height: 34px;
		background-position: -44px -44px;
		background-size: 100px 100px;
	}
	100% {
		box-shadow: 2px 2px 8px 0px rgba(255, 61, 0, 0.5);
		width: 30px;
		height: 28px;
		background-position: -36px -36px;
		background-size: 80px 80px;
	}
}

.loader2 {
	color: #192a56;
	display: inline-block;
	position: relative;
	font-size: 31px;
	font-family: Arial, Helvetica, sans-serif;
	box-sizing: border-box;
	margin: 3rem 0;
	animation: animloaderBack 1s linear infinite alternate;
}
.loader2::after {
	content: "";
	width: 5px;
	height: 5px;
	background: currentColor;
	position: absolute;
	bottom: 10px;
	right: -5px;
	box-sizing: border-box;
	animation: animloader2 1s linear infinite;
}

@keyframes animloader2 {
	0% {
		box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
	}
	50% {
		box-shadow: 10px 0 #192a56, 20px 0 rgba(255, 255, 255, 0);
	}
	100% {
		box-shadow: 10px 0 #192a56, 20px 0 #192a56;
	}
}
